<template>
  <div v-show="show">
    <slot></slot>
  </div>
</template>

<script>
const getParent = ($component) => ($component.abstract || $component.$el === $component.$children[0].$el ? getParent($component.$parent) : $component)

export default {
  name: 'MapInfoWindow',
  props: {
    show: {
      type: Boolean
    },
    position: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: () => {}
    },
    width: {
      type: Number,
      default: () => {}
    },
    height: {
      type: Number,
      default: () => {}
    },
    maxWidth: {
      type: Number,
      default: () => {}
    },
    offset: {
      type: Object,
      default: () => {}
    },
    maximize: {
      type: Boolean
    },
    autoPan: {
      type: Boolean
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: () => {}
    }
  },
  data() {
    return {
      pt: null
    }
  },
  watch: {
    show(val) {
      val ? this.openInfoWindow() : this.closeInfoWindow()
    },
    'position.lng'(val, oldVal) {
      this.reload()
    },
    'position.lat'(val, oldVal) {
      this.reload()
    },
    'offset.width'(val, oldVal) {
      this.reload()
    },
    'offset.height'(val) {
      this.reload()
    },
    maxWidth() {
      this.reload()
    },
    width(val) {
      this.originInstance.setWidth(val)
    },
    height(val) {
      this.originInstance.setHeight(val)
    },
    title(val) {
      this.originInstance.setTitle(val)
    },
    maximize(val) {
      val ? this.originInstance.enableMaximize() : this.originInstance.disableMaximize()
    },
    autoPan(val) {
      val ? this.originInstance.enableAutoPan() : this.originInstance.disableAutoPan()
    },
    closeOnClick(val) {
      val ? this.originInstance.enableCloseOnClick() : this.originInstance.disableCloseOnClick()
    }
  },
  mounted() {
    const $parent = getParent(this.$parent)
    const map = $parent.map
    const { ready } = this
    map ? ready() : $parent.$on('ready', ready)
  },
  destroyed() {
    this.unload()
  },
  beforeDestroy() {
    this.unload()
  },
  methods: {
    unload() {
      const { map, originInstance } = this
      map['removeOverlay'](originInstance)
    },
    ready() {
      const $parent = getParent(this.$parent)
      const BMap = (this.BMap = $parent.BMap)
      const map = (this.map = $parent.map)
      this.load()
      this.$emit('ready', {
        BMap,
        map
      })
    },
    redraw() {
      this.originInstance.redraw()
    },
    reload() {
      this &&
        this.BMap &&
        this.$nextTick(() => {
          this.$nextTick(this.load)
        })
    },
    bindEvent(instance) {
      const ev = ['close', 'open', 'maximize', 'restore', 'clickclose']
      ev &&
        ev.forEach((event) => {
          const hasOn = event.slice(0, 2) === 'on'
          const eventName = hasOn ? event.slice(2) : event
          const listener = this.$listeners[eventName]
          listener && instance.addEventListener(event, listener.fns)
        })
    },
    load() {
      console.log('MapInfoWindow-load')
      if (!this.originInstance) {
        const { BMap, map, show, title, width, height, maxWidth, autoPan, closeOnClick, message, maximize, $parent } = this
        const $content = this.$el
        const overlay = new BMap.InfoWindow($content, {
          width,
          height,
          title,
          maxWidth,
          enableAutoPan: autoPan,
          enableCloseOnClick: closeOnClick,
          enableMessage: typeof message === 'undefined',
          message
        })

        maximize ? overlay.enableMaximize() : overlay.disableMaximize()
        this.bindEvent(overlay)
        this.originInstance = overlay
        overlay.redraw()
        this.$container = $parent.originInstance && $parent.originInstance.openInfoWindow ? $parent.originInstance : map
        show && this.openInfoWindow()
      } else {
        const { show } = this
        show && this.openInfoWindow()
      }
    },
    createPoint(BMap, options = {}) {
      if (!options) {
        return {}
      }
      const { lng, lat } = options
      if (!this.pt) {
        this.pt = new BMap.Point(lng, lat)
      } else {
        this.pt.lng = lng
        this.pt.lat = lat
      }
      return this.pt
    },
    openInfoWindow() {
      console.log('MapInfoWindow-openInfoWindow')
      const { BMap, $container, position, originInstance } = this
      $container.openInfoWindow(originInstance, this.createPoint(BMap, position))
    },
    closeInfoWindow() {
      console.log('MapInfoWindow-closeInfoWindow')
      this.$container.closeInfoWindow(this.originInstance)
    }
  }
}
</script>
